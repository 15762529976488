import React from 'react';

import { cx } from '../utils/cx';

export const Chip = (props: {
  children?: string | null;
  className?: string;
}) => {
  return (
    <span
      className={cx(
        'inline-block text-xs font-medium text-gray-500 dark:text-gray-400 bg-gray-100 dark:bg-gray-900 border-2 border-dashed border-primary-50 dark:border-gray-800 rounded-xl px-2 py-1',
        props.className ?? ''
      )}
    >
      {props.children}
    </span>
  );
};
