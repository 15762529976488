import React, { Fragment } from 'react';

import { Link } from 'gatsby';

export type BreadcrumbItem = { label: string; href?: string };

type BreadcrumbProps = {
  items: BreadcrumbItem[];
};

export const Breadcrumb = (props: BreadcrumbProps) => {
  return props.items.length ? (
    <div className="text-gray-500 dark:text-gray-500 flex gap-x-2">
      {props.items.map(({ href, label }, key) => {
        const isLastItem = props.items.length === key + 1;
        const isLink = !!href;
        return (
          <Fragment key={key}>
            {isLink ? <Link to={href}>{label}</Link> : <span>{label}</span>}
            {!isLastItem ? <Separator /> : null}
          </Fragment>
        );
      })}
    </div>
  ) : null;
};

const Separator = () => {
  return <span className="text-gray-400 dark:text-gray-600">/</span>;
};
