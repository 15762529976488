import React from 'react';

import { graphql, PageProps } from 'gatsby';

import { Layout } from '../../components/Layout';
import { SEO } from '../../components/SEO';
import { ContentWrapper } from '../../components/ContentWrapper';
import { Breadcrumb, BreadcrumbItem } from '../../components/Breadcrumb';
import { Divider } from '../../components/Divider';
import { Chip } from '../../components/Chip';
import { Share } from '../../components/Share';
import { capitalize } from '../../utils/capitalize';
import { useCommentBox } from '../../hooks/useCommentBox';

type BlogPostData = {
  markdownRemark: Queries.BlogPostFragment;
};

const BlogPost = (props: PageProps<BlogPostData>) => {
  const { html, frontmatter } = props.data.markdownRemark;
  const { category, title, date, tags } = frontmatter ?? {};

  const breadcrumb: BreadcrumbItem[] = [{ label: 'All Posts', href: '/blog' }];
  if (category) breadcrumb.push({ label: capitalize(category) });

  const CommentBox = useCommentBox();

  return (
    <Layout>
      <ContentWrapper className="max-w-[40rem] mb-40">
        <article>
          <section id="header" className="mt-4 mb-16 overflow-hidden">
            <Breadcrumb items={breadcrumb} />
            <h1 className="text-4xl mt-4 mb-6">{title}</h1>
            <div className="flex items-center">
              <div className="flex-shrink-0 text-gray-500 dark:text-gray-500 text-sm">
                <span className="font-medium">by Brendan Horan</span>{' '}
                <span className="text-gray-400 dark:text-gray-600 px-2">–</span>
                <span>{date}</span>
              </div>
              <Divider />
            </div>
          </section>
          <div
            id="content"
            className="prose dark:prose-invert"
            dangerouslySetInnerHTML={{ __html: html ?? '' }}
          />
          <section
            id="footer"
            className="my-16 flex flex-col md:flex-row md:justify-between md:items-center gap-8"
          >
            <div className="flex flex-wrap gap-2">
              {!!tags?.length
                ? tags.map((tag, key) => <Chip key={key}>{tag}</Chip>)
                : null}
            </div>
            <Share type="Post" url={props.location.href} />
          </section>
          {CommentBox}
        </article>
      </ContentWrapper>
    </Layout>
  );
};

export const query = graphql`
  fragment BlogPostFrontmatter on MarkdownRemark {
    frontmatter {
      title
      date(formatString: "MMMM D, YYYY")
      category
      tags
      slug
      summary
    }
  }

  fragment BlogPost on MarkdownRemark {
    html
    ...BlogPostFrontmatter
  }

  query ($id: String) {
    markdownRemark(id: { eq: $id }) {
      ...BlogPost
    }
  }
`;

export default BlogPost;

export const Head = (props: PageProps<BlogPostData>) => {
  const { title, summary } = props.data.markdownRemark.frontmatter ?? {};
  const seoTitle = title ? `${title} by Brendan Horan` : undefined;
  return <SEO title={seoTitle} description={summary ?? undefined} />;
};
