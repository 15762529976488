import React from 'react';

import { ExternalLink } from './ExternalLink';
import { LinkedInSVG, TwitterSVG } from './Icons';

type ShareProps = {
  type: string;
  url: string;
};

export const Share = (props: ShareProps) => {
  return (
    <div className="flex flex-shrink-0 items-center justify-start gap-x-4">
      <span className="text-xs text-gray-500 dark:text-gray-500">
        Share this {props.type}
      </span>
      <ExternalLink
        href={createShareableLinkedInLink(props.url)}
        title="Share on LinkedIn"
      >
        {React.cloneElement(LinkedInSVG, {
          className: `w-5 h-5 link-button`,
        })}
      </ExternalLink>
      <ExternalLink href={createTweetLink(props.url)} title="Share on Twitter">
        {React.cloneElement(TwitterSVG, {
          className: `w-5 h-5 link-button`,
        })}
      </ExternalLink>
    </div>
  );
};

const createShareableLinkedInLink = (url: string) => {
  const encodedUrl = fixedEncodeURIComponent(url);
  return `https://www.linkedin.com/sharing/share-offsite/?url=${encodedUrl}`;
};

const createTweetLink = (url: string) => {
  const twitterUrl = 'https://twitter.com/intent/tweet';
  return `${twitterUrl}?url=${url}`;
};

const fixedEncodeURIComponent = (str: string) => {
  return encodeURIComponent(str).replace(/[!'()*]/g, function (c) {
    return '%' + c.charCodeAt(0).toString(16);
  });
};
