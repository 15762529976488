import React, { useEffect } from 'react';

import { graphql, useStaticQuery } from 'gatsby';
import commentBox from 'commentbox.io';
import useDarkMode from 'use-dark-mode';

export const useCommentBox = () => {
  const { value: isDarkMode } = useDarkMode();

  const data = useStaticQuery<Queries.CommentBoxQuery>(query);
  const projectId = data.allContentJson.nodes[0].commentbox?.projectId;

  useEffect(() => {
    const removeCommentBox = commentBox(projectId, {
      textColor: isDarkMode ? 'white' : 'black',
    });
    return () => {
      removeCommentBox();
    };
  }, []);

  return projectId ? <section id="comments" className="commentbox" /> : null;
};

const query = graphql`
  query CommentBox {
    allContentJson {
      nodes {
        commentbox {
          projectId
        }
      }
    }
  }
`;
